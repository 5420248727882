<template>
  <v-container fluid>
     <v-btn class="ma-2" tile large color="green darken-2" @click="printform()" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    <v-row>
      <v-card>
        <table width="1300" align="center">
          <tr>
            <td>
              <h1>
                {{currentCase.participantDetails.firstname}}
                {{currentCase.participantDetails.lastname}}
              </h1>
            </td>
            <td>
              <v-avatar size="80px">
                <v-img
                  v-if="currentCase.participantDetails.photoURL"
                  v-bind:src="currentCase.participantDetails.photoURL"
                  alt="Avatar"
                  aspect-ratio="1"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-icon
                  v-if="!currentCase.participantDetails.photoURL"
                  color="green darken-2"
                  size="100"
                >mdi-account-circle</v-icon>
              </v-avatar>
            </td>
            <td>
              <b>Date of Birth:</b>
              {{currentCase.participantDetails.dateOfBirth}}
              <br />
              <b>Gender:</b>
              {{currentCase.participantDetails.gender}}
              <br />
              <b>Marital Status:</b>
              {{currentCase.participantDetails.maritalStatus}}
            </td>
            <td width="100"></td>
            <td>
              <b>Primary Disability:</b>
              {{currentCase.participantDetails.primaryDisability}}
              <br />
              <b>Secondary Disability:</b>
              {{currentCase.participantDetails.secondaryDisability}}
            </td>
          </tr>
        </table>
        <svg height="10" width="500" align="center">
          <line x1="0" y1="0" x2="500" y2="0" style="stroke:rgb(255,0,0);stroke-width:2" />
        </svg>
        <table width="1500">
          <tr>
            <td>
              <b>Residential Address:</b>
              {{currentCase.participantDetails.address}}
            </td>
            <td>
              <b>Home Phone:</b>
              {{currentCase.participantDetails.homeNumber}}
            </td>
            <td>
              <b>Mobile Number:</b>
              {{currentCase.participantDetails.mobileNumber}}
            </td>
            <td>
              <b>Email:</b>
              {{currentCase.participantDetails.email}}
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <td>
              <b>Postal Address:</b>
              {{currentCase.participantDetails.address}}
            </td>
            <td>
              <b>NDIS Number:</b>
              {{currentCase.participantDetails.nDISNumber}}
            </td>
            <td>
              <b>Medicare Number:</b>
              {{currentCase.participantDetails.medicareNumber}}
            </td>
            <td>
              <b>Taxfile Number:</b>
              {{currentCase.participantDetails.taxFileNumber}}
            </td>
            <td>
              <b>Supported Wage System number Number:</b>
              {{currentCase.participantDetails.sWSNumber}}
            </td>
            <td>
              <b>CRN Number:</b>
              {{currentCase.participantDetails.cRNNumber}}
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <h4>Consent Details</h4>
          </tr>
          <tr>
            <td>
              <b>Consent given for photos of {{currentCase.participantDetails.firstname}} to be used for Marketing purposes?:</b>
              {{currentCase.consentDetails.hasConsentedToTakePhotos}}
              <br />
              <b>Consent Given by:</b>
              {{currentCase.consentDetails.personGivingConsentPhotos}}
            </td>
            <td>
              <b>Consent given to share {{currentCase.participantDetails.firstname}}'s information with Government Bodies?</b>
              {{currentCase.consentDetails.hasConsentedToShareWithGov}}
              <br />
              <b>Consent Given by:</b>
              {{currentCase.consentDetails.personGivingConsentGov}}
            </td>
            <td>
              <b>Consent given to share {{currentCase.participantDetails.firstname}}'s information with {{currentCase.participantDetails.firstname}}'s' own parents/carers?</b>
              {{currentCase.consentDetails.hasConsentedoShareWithParents}}
              <br />
              <b>Consent Given by:</b>
              {{currentCase.consentDetails.personGivingConsentParents}}
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <h4>Living arrangements</h4>
          </tr>
          <tr>
            <td>
              <b>Living Arrangements:</b>
              {{currentCase.livingArrangments.livingArrangements}}
            </td>
            <td>
              <b>Other Occupants:</b>
              {{currentCase.livingArrangments.otherOccupants}}
            </td>
            <td>
              <b>Is {{currentCase.participantDetails.firstname}} currently living in a Refuge,Emergency or Support Accomodation, Hostel,Boarding House, Hotel, Short Stay in Caravan Park, Staying with friends or have nowhere to stay?:</b>
              {{currentCase.livingArrangments.isSpecialAccommodation}}
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <h4>{{currentCase.participantDetails.firstname}}'s Features</h4>
          </tr>
          <tr>
            <td>
              <b>Weight:</b>
              {{currentCase.features.weight}} kg
            </td>
            <td>
              <b>Height:</b>
              {{currentCase.features.height}} cm
            </td>
            <td>
              <b>Hair Colour:</b>
              {{currentCase.features.hairColour}}
            </td>
            <td>
              <b>Eye Colour:</b>
              {{currentCase.features.eyeColour}}
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              <b>Distinguishing Features:</b>
              {{currentCase.features.distinguishingFeatures}}
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <h4>{{currentCase.participantDetails.firstname}}'s Culture</h4>
          </tr>
          <tr>
            <td>
              <b>Indigenous Status:</b>
              {{currentCase.culture.indigenousStatus}}
            </td>
            <td>
              <b>Country of Birth:</b>
              {{currentCase.culture.countryOfBirth}}
            </td>
            <td>
              <b>Residency Status:</b>
              {{currentCase.culture.residencyStatus}}
            </td>
            <td>
              <b>Ethnicity:</b>
              {{currentCase.culture.ethnicity}}
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <h4>Communication</h4>
          </tr>
          <tr>
            <td>
              <b>Primary Language:</b>
              {{currentCase.communication.primaryLanguage}}
            </td>
            <td>
              <b>Preferred Communication Method:</b>
              {{currentCase.communication.communicationMethod}}
            </td>
            <td>
              <b>Interpreter Services:</b>
              {{currentCase.communication.interpreterServices}}
            </td>
          </tr>
        </table>
        <table width="1500">
          <tr>
            <h4>Police Record</h4>
          </tr>
          <tr>
            <td valign="top">
              <b>Has {{currentCase.participantDetails.firstname}} been convicted of a criminal offence as a juvenile in the last 5 years?</b>
              {{currentCase.policeRecord.hasCriminalConvictionJuvenileWithin5Years}}
            </td>
            <td valign="top">
              <b>Has {{currentCase.participantDetails.firstname}} been convicted of a criminal offence as an adult in the last 10 years?</b>
              {{currentCase.policeRecord.hasCriminalConvictionAdultWithin10Years}}
            </td>
            <td valign="top">
              <b>Did {{currentCase.participantDetails.firstname}} serve a custodial sentence of more than one month?</b>
              {{currentCase.policeRecord.interpreterServices}}
              <br />
              <small>This will be blank if the last two questions are false</small>
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <td>
              <h4>{{currentCase.participantDetails.firstname}}'s Support Needs</h4>
            </td>
            <td>
              <h5>Scale: 1=Low, 2=Medium, 3=High</h5>
            </td>
          </tr>
          <tr>
            <td>
              <b>Self Care:</b>
              {{currentCase.supportNeeds.selfCare}}
            </td>
            <td>
              <b>mobility:</b>
              {{currentCase.supportNeeds.mobility}}
            </td>
            <td>
              <b>interpersonalInteractionsAndRelationships:</b>
              {{currentCase.supportNeeds.interpersonalInteractionsAndRelationships}}
            </td>
            <td>
              <b>learningApplyingKnowledgeAndGeneralTasksDemands:</b>
              {{currentCase.supportNeeds.learningApplyingKnowledgeAndGeneralTasksDemands}}
            </td>
          </tr>
          <tr>
            <td>
              <b>education:</b>
              {{currentCase.supportNeeds.education}}
            </td>
            <td>
              <b>communicationCivicAndEconomicLife:</b>
              {{currentCase.supportNeeds.communicationCivicAndEconomicLife}}
            </td>
            <td>
              <b>toileting:</b>
              {{currentCase.supportNeeds.toileting}}
            </td>
            <td>
              <b>eatingAndDrinking:</b>
              {{currentCase.supportNeeds.eatingAndDrinking}}
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <h4>Community Access</h4>
          </tr>
          <tr>
            <td>
              <b>Activities {{currentCase.participantDetails.firstname}} enjoys:</b>
              {{currentCase.communityAccess.activitiesEnjoyedByClient}}
            </td>
            <td>
              <b>Activities {{currentCase.participantDetails.firstname}} does not enjoy:</b>
              {{currentCase.communityAccess.activitiesNotEnjoyedByClient}}
            </td>
          </tr>
          <tr>
            <td>
              <b>Activities {{currentCase.participantDetails.firstname}} cannot do due to medical reasons:</b>
              {{currentCase.communityAccess.activitiesClientCannotDoDueToMedicalReasons}}
            </td>
          </tr>
        </table>

        <table width="1500" class="h4pre" >
          <tr>
            <h4>Safety and Outings</h4>
          </tr>
          <tr>
            <td>
              <b>Is {{currentCase.participantDetails.firstname}} likely to wander off / abscond?</b>
              {{currentCase.behaviorForm.isLikelyToWanderOff}}
            </td>
            <td>
              <b>Is {{currentCase.participantDetails.firstname}} likely to remain seated whilst traveling?</b>
              {{currentCase.behaviorForm.isAbleToRemainSeatedWhilstTraveling}}
            </td>
            <td>
              <b>Is {{currentCase.participantDetails.firstname}} likely to remove their seatbelt whilst traveling?</b>
              {{currentCase.behaviorForm.isLikelyToRemoveSeatBelt}}
            </td>
            <td>
              <b>Does {{currentCase.participantDetails.firstname}} require activities whilst traveling (e.g books, music etc)?</b>
              {{currentCase.behaviorForm.hasActivitiesRequirementWhilstTraveling}}
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              <b>List of the activities:</b>
              {{currentCase.behaviorForm.listOfTravelActivities}}
              <small>| This will be blank if the "requires activities while travelling" question is false</small>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td valign="top">
              <b>Recommended maximum travel time</b>
              {{currentCase.behaviorForm.recommenedMaxTravelTime}}
            </td>
            <td valign="top">
              <b>Does {{currentCase.participantDetails.firstname}} show warning signs of potential behaviour difficulties whilst on outings?</b>
              {{currentCase.behaviorForm.hasWarningSignsOfBehaviourDifficulties}}
              <br />
              <b>Does the participant have a Positive Behaviour Support Plan?</b>
              {{currentCase.behaviorForm.hasGotPBSP}}
            </td>
            <td valign="top">
              <b>List the warning signs?</b>
              {{currentCase.behaviorForm.listWarningSigns}}
              <br />
              <small>This will be blank if the "show warning signs" question is false</small>
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <td>
              <h4>{{currentCase.participantDetails.firstname}}'s Medical Details</h4>
            </td>
          </tr>
          <tr>
            <td>
              <b>Private Health Insurance Details</b>
              {{currentCase.medical.privateHealthInsuranceDetails}}
            </td>
            <td>
              <b>Is {{currentCase.participantDetails.firstname}} a smoker?</b>
              {{currentCase.medical.isSmoker}}
            </td>
            <td>
              <b>Does {{currentCase.participantDetails.firstname}} have a Seizure Plan or Other?</b>
              {{currentCase.medical.hasSeizurePlan}}
            </td>
            <td>
              <b>Is special care nursing required?</b>
              {{currentCase.medical.doSpecialCareNursingRequired}}
            </td>
          </tr>
          <tr>
            <td>
              <b>Does {{currentCase.participantDetails.firstname}} have Nutrition/Swalloing Issues?</b>
              {{currentCase.medical.nutritionSwalloingIssues}}
            </td>
            <td>
              <b>Does {{currentCase.participantDetails.firstname}} have regular Tetanus injections?</b>
              {{currentCase.medical.tetanus}}
            </td>
            <td>
              <b>Last injection:</b>
              {{currentCase.medical.tetanusDateLastReceived}}
            </td>
            <td>
              <b>Does {{currentCase.participantDetails.firstname}} have regular Hep A injections?</b>
              {{currentCase.medical.hepatitis}}
            </td>
            <td>
              <b>Last injection:</b>
              {{currentCase.medical.hepatitisDateLastReceived}}
            </td>
          </tr>
        </table>

        <table width="1500">
          <tr>
            <td>
              <h4>{{currentCase.participantDetails.firstname}}'s employment, training and education</h4>
            </td>
          </tr>
          <tr>
            <td>
              <b>Main income source</b>
              {{currentCase.employmentTrainingEducation.mainIncomeSource}}
            </td>
            <td>
              <b>Employment Status</b>
              {{currentCase.employmentTrainingEducation.employment}}
            </td>
            <td>
              <b>Last two years main work participation type</b>
              {{currentCase.employmentTrainingEducation.recentWorkParticipation}}
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              <b>{{currentCase.participantDetails.firstname}}'s Literacy skills</b>
              {{currentCase.employmentTrainingEducation.literacySkills}}
            </td>
            <td>
              <b>{{currentCase.participantDetails.firstname}}'s Numeracy skills</b>
              {{currentCase.employmentTrainingEducation.numeracySkills}}
            </td>
            <td>
              <b>{{currentCase.participantDetails.firstname}}'s Highest level of schooling</b>
              {{currentCase.employmentTrainingEducation.highestLevelOfSchooling}}
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td valign="top">
              <b>Does {{currentCase.participantDetails.firstname}} have any other work related qualifications?</b>
              {{currentCase.employmentTrainingEducation.hasAnyOtherWorkRelatedQualifications}}
            </td>
            <td valign="top">
              <b>List of qualifications:</b>
              {{currentCase.employmentTrainingEducation.listOtherQualifications}}
              <br />
              <small>This will be blank if the "has other qualifications" question is false</small>
            </td>
            <td valign="top">
              <b>Is {{currentCase.participantDetails.firstname}} still able to use their Qualification?</b>
              {{currentCase.employmentTrainingEducation.isAbleToStillUseQualification}}
            </td>
          </tr>
        </table>
         <table width="1500">
          <tr>
            <td>
              <h4>{{currentCase.participantDetails.firstname}}'s Medical Contacts</h4>
            </td>
          </tr>
         </table>
        <v-row>
          <v-col cols="12">
      <v-data-table
        id="list-table"
        ref="datatable"
        :headers="headers"
        :items="medContacts"
        
        item-key="index"
        :hide-default-footer="true"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td>{{ props.item.contactType}}</td>
            <td>{{ props.item.firstName}}</td>
            <td>{{ props.item.familyName}}</td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.mobilePhone }}</td>
            <td>{{ props.item.workPhone }}</td>
          </tr>
        </template>
      </v-data-table>
          </v-col>
    </v-row>
      <table width="1500">
          <tr>
            <td>
              <h4>{{currentCase.participantDetails.firstname}}'s Medication</h4>
            </td>
          </tr>
         </table>  

 <table width="1500">
          <tr>
            <td valign="top">
              <b>Medical Notes:</b>
              {{currentCase.medication.medicalNotes}}
            </td>
            <td valign="top">
              <b>Preferred Pain Relief</b>
              {{currentCase.medication.prefferredPainRelief}}              
            </td>
           
          </tr>
          <tr>
            <td valign="top">
              <b>Allergies and Reactions</b>
              {{currentCase.medication.allergiesAndReactions}}
            </td>
            <td valign="top">
              <b>Treatments</b>
              {{currentCase.medication.prefferredPainRelief}}              
            </td>
          </tr>
        </table>
<participant-details-medication></participant-details-medication>
<table  class="h4pre" ></table>
<h4>{{currentCase.participantDetails.firstname}}'s Contacts</h4>
<participant-details-contacts> </participant-details-contacts>

      </v-card>
    </v-row>
  </v-container>
</template>






<script>
var Models = require("../../../../participant-management/forms/models.js");
import medicationForm from "./participant-details-medication";
import contacts from "./participant-details-contacts";
export default {
  name: "participant-details",
      
  data: () => ({
vm: new Models.MedicalContacts(),
medContacts: [],

 headers: [
      { text: "Contact Type", value: "contactType" },
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "familyName" },
      { text: "Email", value: "email" },
      { text: "Mobile Phone", value: "mobilePhone" },
      { text: "Work Phone", value: "workPhone" }
    ]
    }),
  mounted() {
    this.init();
  },
  components: {
    "participant-details-medication": medicationForm,
    "participant-details-contacts": contacts
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    printform(){
      print()
    },
    init() {
       this.vm = {};
      this.medContacts =
        this.currentCase.medicalContacts === undefined
          ? []
          : this.currentCase.medicalContacts;
         
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>

